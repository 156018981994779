import './index.less'

import { Icon, Menu, Dropdown } from 'ant-design-vue'
import { i18nRender } from '@/locales'
import i18nMixin from '@/store/i18n-mixin'

//配置需要展示的多语言 目前只支持 zh-CN  en-US  ko-KR  ru-RU
const $openLangList = [];
globalThis.$openLangList = $openLangList


const locales = $openLangList
const languageLabels = {};
const languageIcons = {};
for (var i=0;i<locales.length;i++){
    if(locales[i] == "zh-CN"){
      languageLabels['zh-CN'] = '简体中文';
      languageIcons['zh-CN'] = '🇨🇳';
    }else if(locales[i] == "en-US"){
      languageLabels['en-US'] = 'English';
      languageIcons['en-US'] = '🇺🇸';
    }else if(locales[i] == "ko-KR"){
      languageLabels['ko-KR'] = '한국어';
      languageIcons['ko-KR'] = 'ko';
    }else if(locales[i] == "ru-RU"){
      languageLabels['ru-RU'] = 'Русский язык';
      languageIcons['ru-RU'] = 'ru';
    }
}


// const locales = ['zh-CN', 'en-US','ko-KR','ru-RU']
//
//
// const languageLabels = {
//   'zh-CN': '简体中文',
//   'en-US': 'English',
//   'ko-KR': '한국어',
//   'ru-RU': 'Русский язык'
//
// }
// // eslint-disable-next-line
// const languageIcons = {
//   'zh-CN': '🇨🇳',
//   'en-US': '🇺🇸',
//   'ko-KR': 'ko',
//   'ru-RU': 'ru'
// }

const SelectLang = {
  props: {
    prefixCls: {
      type: String,
      default: 'ant-pro-drop-down'
    }
  },
  name: 'SelectLang',
  mixins: [i18nMixin],
  render () {
    const { prefixCls } = this
    const changeLang = ({ key }) => {
      console.log('切换语言为',key)
      this.setLang(key)
    }
    const langMenu = (
      <Menu class={['menu', 'ant-pro-header-menu']} selectedKeys={[this.currentLang]} onClick={changeLang}>
        {locales.map(locale => (
          <Menu.Item key={locale}>
            <span role="img" aria-label={languageLabels[locale]}>
              {languageIcons[locale]}
            </span>{' '}
            {languageLabels[locale]}
          </Menu.Item>
        ))}
      </Menu>
    )
    return (
      <Dropdown overlay={langMenu} placement="bottomRight">
        <span class={prefixCls}>
          <Icon type="global" title={i18nRender('navBar.lang')} />
        </span>
      </Dropdown>
    )
  }
}

export default SelectLang
